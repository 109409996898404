import Card from "react-bootstrap/Card";
import React, {useContext} from "react";
import {Form} from "react-bootstrap";
import ExpandableText from "../../misc/ExpandableText/ExpandableText";
import QuestionCard from "../shared/QuestionCard/QuestionCard";
import './SingleChooseOneDropdownQuestion.css';
import LanguageContext from "../../../contexts/LanguageContext";
import getText from "../../../util/texts/getText";
import {FaSpinner} from "react-icons/fa";
import useChooseOneDeSelectionFunction from "../shared/useChooseOneDeSelectionFunction";
import {ChooseOneQuestion} from "../../../models/questions/ChooseOneQuestion";

interface IProps {
    question: ChooseOneQuestion,
    setStatus: (nodeID: string, newStatus: number, performNodeUpdate: boolean) => Promise<any>;
    activeAccordionKey: string;
    setActiveAccordionKey: (newKey: string) => any;
    keyId: string;
}

const SingleChooseOneDropdownQuestion = ({question, setStatus, activeAccordionKey, setActiveAccordionKey, keyId}: IProps) => {
    const [language] = useContext(LanguageContext);

    const mainQuestion = <Card.Title style={{margin: 0}}>
        {question.mainQuestion[language] ?? question.mainQuestion.default}
    </Card.Title>

    const {setSelectionTo} = useChooseOneDeSelectionFunction(question, setStatus);

    const handleOnChange = async (questionId) => {
        await setSelectionTo(questionId)
    }

    return <QuestionCard>
        <ExpandableText
            context={{type: 'question', id: question._id}}
            title={mainQuestion}
            expandableContent={question.moreDetails[language] ?? question.moreDetails.default}
            eventKey={question._key}
            activeAccordionKey={activeAccordionKey}
            setActiveAccordionKey={setActiveAccordionKey}
            style={{gridAutoColumns: 'auto'}}>
            {/* Positioning this element relative to make it the anchor for the Spinner's absolute positioning allowing for easy overlay centering */}
            <div className={'JunBots-SingleQuestion-InputElement'} style={{position: 'relative'}}>
                <OverlaySpinner active={question.isLoading}/>
                <Form>
                    <Form.Select disabled={question.isLoading} onChange={selectedOption => handleOnChange(selectedOption.currentTarget.value)}>
                        <option hidden selected>{getText("pleaseSelect", language)}</option>
                        {
                            question.options.map((singleOption) =>
                                <option selected={singleOption.state === 1} value={singleOption._id} key={'SingleChooseOneWithNoneQuestion' + keyId + '/' + singleOption._id}>
                                    {singleOption.selectionText[language] ?? singleOption.selectionText.default}
                                </option>
                            )
                        }
                    </Form.Select>
                </Form>
            </div>
        </ExpandableText>
    </QuestionCard>;
};


const OverlaySpinner = ({active}) => {
    if(!active) {
        return <></>
    }

    return <div className="overlay-spinner-dropdown">
        <FaSpinner className={'rotating'}/>
    </div>
}

export default SingleChooseOneDropdownQuestion;