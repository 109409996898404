/**
 * Updates the status of a given node. Calls callback-function with response of request.
 *
 * @param {String} baseUrl: the base URL of the correct JunBot Mount
 * @param {String} juriCoreSID: The current session id
 * @param {Function} setJuriCoreSID: function to set the session id
 * @param {String} nodeId: id of the node
 * @param {Number} newStatus: the new status
 * @param {Function} callback: This function is being called without parameters when the response is successful.
 * @param {string} token: the current auth token
 */
import JuriCoreError from "../errors/JuriCoreError";

function setNodeStatus(baseUrl, juriCoreSID, setJuriCoreSID, nodeId, newStatus, token) {
    const url = `${baseUrl}/session/update/${nodeId}`;
    const update = {
        status: newStatus
    };

    return fetch(url, {
        method: 'PATCH', // or 'PUT'
        credentials: 'same-origin',
        body: JSON.stringify(update), // data can be `string` or {object}!
        headers: {
            'Content-Type': 'application/json',
            'X-JuriCORE-SID': juriCoreSID,
            'Authorization': `Bearer ${token}`
        }
    }).then(response => {
        if (!response.ok) throw new JuriCoreError(`error updating status of node '${nodeId}'`, response);
        setJuriCoreSID(response.headers.get('X-JuriCORE-SID'));
    })
}

export default setNodeStatus;