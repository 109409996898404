import React from 'react';
import "./JunBotMarkdown.css"
import Markdown from "markdown-to-jsx";
import MarkdownTooltip from "./MarkdownTooltip/MarkdownTooltip";
import {replaceIframeWithJunitIframe} from "../../util/replace-iframe-with-junit-iframe";

const _ = require('lodash');

function CustomLink({children, href, title}) {
    return <a
        href={href}
        title={title}
        rel={'noopener noreferrer'}
        target={'_blank'}
    >{children}</a>
}

/**
 * A custom Markdown interpreter which uses markdown-to-jsx.
 * The only addition is a <Tooltip text='Tooltip Text'> HTML-tag.
 *
 * It supports multiple children. Each child of type String is individually passed to one markdown-to-jsx object.
 * Children of other types are rendered as they are.
 *
 * @param children: Each child of type String is directly passed to markdown-to-jsx. Other children are directly rendered.
 * @param props: other properties directly passed to markdown-to-jsx
 * @return {*}
 * @constructor
 */
function JunBotMarkdown({children, ...props}) {
    let parsedChildren = React.Children.map(children, child => {
        if (_.isString(child)) {
            return <Markdown options={{
                overrides: {
                    Tooltip: MarkdownTooltip,
                    a: CustomLink
                }
            }} style={{display: 'inline'}} {...props}>
                {replaceIframeWithJunitIframe(child) || ''}
            </Markdown>
        }
        return child
    });

    return (<div>{parsedChildren}</div>)
}

export default JunBotMarkdown