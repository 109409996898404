import React, {useContext, useEffect, useState} from 'react';
import './JunBot.css';
import Bot from "../../components/Bot/Bot";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {IconContext} from "react-icons";
import {FaRegQuestionCircle, FaHome} from "react-icons/fa";
import JunBotMarkdown from "../../components/JunbotMarkdown/JunBotMarkdown";
import Button from "react-bootstrap/Button";
import {Link, Redirect} from "react-router-dom";
import Connector from "../../JuriCore/Connector";
import Disclaimer from "../../components/Texts/Disclaimer/Disclaimer";
import IsMobileContext from "../../contexts/IsMobileContext";
import LanguageContext from "../../contexts/LanguageContext";
import Credits from "../../components/Texts/Credits/Credits";
import JunBotSettingsContext from "../../contexts/JunBotSettingsContext";
import LanguageSwitch from "../../components/misc/LanguageSwitch/LanguageSwitch";
import Node from "../../JuriCore/Node";
import {GraphNodeValues} from "../../models/graph/GraphNode";
import {JuriCoreContext} from "../../JuriCore/JuriCoreContext";
import {
    useHistory,
} from "react-router-dom";
import getText from "../../util/texts/getText";
import {useAuth} from "react-oidc-context";
const _ = require('lodash');


interface IProps {
    junBotGraphName: string;
}

// BotId -> Redirection to Url
const botRedirections = new Map<string, string>();
botRedirections.set('DONT_DELETE', '/junbots');
botRedirections.set('Help_for_Ukrainians', '/junbots/Help_for_Ukrainians_v2');


function JunBot({junBotGraphName}: IProps) {
    const {setSessionID} = useContext(JuriCoreContext);
    const [junBotSettings, setJunBotSettings] = useState<GraphNodeValues | null>(null);
    const [possibleLanguages, setPossibleLanguages] = useState([])
    const isMobile = useContext(IsMobileContext);
    const [language, setLanguage] = useContext(LanguageContext);
    const auth = useAuth();

    useEffect(() => {
        Connector.getJunBotSettings(junBotGraphName, setJunBotSettings, auth.user.access_token)
        Connector.increaseVisitCount(junBotGraphName, auth.user.access_token)

        // abort the fetch request. Otherwise, an old fetch may overwrite the new one when redirecting to another graph.
        return () => Connector.abortAll()
    }, [junBotGraphName]);

    useEffect(() => {
        let languages = _.map(_.split(junBotSettings?.language ?? '', ','), _.trim)
        setPossibleLanguages(languages)

        if (languages.length > 0) {
            setLanguage(languages[0]) // default to first language
        }
    }, [setLanguage, junBotSettings]);

    const foundRedirect = botRedirections.get(junBotGraphName);
    if(foundRedirect) {
        return <Redirect to={foundRedirect}/>
    }

    const handleReset = () => {
        setSessionID(null);
    };

    return (
        <JunBotSettingsContext.Provider value={junBotSettings}>
            <Container className={'Page'} fluid>
                <div className={'Headline-Container'}>
                    <div className={'Headline-left'}>
                        {
                            /*
                        <Link to={'/junbots'}>
                            <Button variant={'outline-secondary'} onClick={handleReset}>
                                <FaHome style={{fontSize: '1.5em'}}/>
                            </Button>
                        </Link>
                             */
                        }
                    </div>
                    <h1 className={'Headline-Title sm'}>junBot</h1>
                    <div className={'Headline-right'}>
                        <LanguageSwitch languages={possibleLanguages}
                                        currentLanguage={language}
                                        setLanguage={setLanguage}/>
                    </div>
                </div>
                <Row>
                    {
                        !isMobile && <Col className={'JunBots-Left'}/>
                    }
                    <Col className={'JunBots-Middle Page-Content'}
                         md={'auto'}>
                        <Row>
                            <Col>
                                <Disclaimer/>
                            </Col>
                        </Row>
                        <Row className={'JunBots-JunBot-GeneralQuestion'}>
                            <Container fluid>
                                <Card className={'JunBots-FloatingElement'}>
                                    <Card.Body>
                                        <Card.Title>
                                            <h3>{Node.getProperty(junBotSettings, 'displayName', language)}</h3>
                                        </Card.Title>
                                        <JunBotMarkdown>
                                            <IconContext.Provider value={{size: '1.2em'}}>
                                                <FaRegQuestionCircle/>
                                            </IconContext.Provider>
                                            {" " + Node.getProperty(junBotSettings, 'question', language)}
                                        </JunBotMarkdown>
                                    </Card.Body>
                                </Card>
                            </Container>
                        </Row>
                        <Row>
                            { junBotSettings && !(junBotSettings.accessible ?? true)
                                ? <h2 style={{marginTop: '5rem', marginBottom: '6rem'}}>{getText('bot-not-available', language)}</h2>
                                : <Bot graphName={junBotGraphName}/>
                            }
                        </Row>
                        <Row>
                            <Credits junBotGraphName={junBotGraphName}/>
                        </Row>
                    </Col>
                    {
                        !isMobile && <Col className={'JunBots-Right'}/>
                    }
                </Row>
            </Container>
        </JunBotSettingsContext.Provider>
    )
}

export default JunBot;
