const LanguageCodes = [
    "ps_AF",
    "fa_AF",
    "uz_AF",
    "sv_AX",
    "sq_AL",
    "en_AL",
    "ar_DZ",
    "fr_DZ",
    "kab_DZ",
    "en_AS",
    "ca_AD",
    "en_AD",
    "ln_AO",
    "pt_AO",
    "en_AI",
    "es_AI",
    "en_AG",
    "es_AG",
    "en_AR",
    "es_AR",
    "hy_AM",
    "nl_AW",
    "es_AW",
    "en_AU",
    "en_AT",
    "de_AT",
    "az_AZ",
    "en_BS",
    "es_BS",
    "ar_BH",
    "bn_BD",
    "ccp_BD",
    "en_BD",
    "en_BB",
    "es_BB",
    "be_BY",
    "ru_BY",
    "nl_BE",
    "en_BE",
    "fr_BE",
    "de_BE",
    "wa_BE",
    "en_BZ",
    "es_BZ",
    "fr_BJ",
    "yo_BJ",
    "en_BM",
    "es_BM",
    "dz_BT",
    "qu_BO",
    "es_BO",
    "bs_BA",
    "hr_BA",
    "en_BA",
    "sr_BA",
    "en_BW",
    "tn_BW",
    "en_BR",
    "pt_BR",
    "es_BR",
    "en_IO",
    "en_VG",
    "es_VG",
    "ms_BN",
    "bg_BG",
    "en_BG",
    "fr_BF",
    "ff_BF",
    "en_BI",
    "fr_BI",
    "rn_BI",
    "km_KH",
    "agq_CM",
    "ksf_CM",
    "bas_CM",
    "dua_CM",
    "en_CM",
    "ewo_CM",
    "fr_CM",
    "ff_CM",
    "kkj_CM",
    "nmg_CM",
    "mgo_CM",
    "mua_CM",
    "nnh_CM",
    "jgo_CM",
    "yav_CM",
    "en_CA",
    "fr_CA",
    "iu_CA",
    "moh_CA",
    "es_CA",
    "es_IC",
    "kea_CV",
    "pt_CV",
    "nl_BQ",
    "es_BQ",
    "en_KY",
    "es_KY",
    "fr_CF",
    "ln_CF",
    "sg_CF",
    "es_EA",
    "ar_TD",
    "fr_TD",
    "en_CL",
    "arn_CL",
    "es_CL",
    "yue_CN",
    "zh_CN",
    "en_CN",
    "ii_CN",
    "bo_CN",
    "ug_CN",
    "en_CX",
    "en_CC",
    "en_CO",
    "es_CO",
    "ar_KM",
    "fr_KM",
    "fr_CG",
    "ln_CG",
    "fr_CD",
    "ln_CD",
    "lu_CD",
    "sw_CD",
    "en_CK",
    "es_CR",
    "fr_CI",
    "hr_HR",
    "en_HR",
    "es_CU",
    "nl_CW",
    "es_CW",
    "en_CY",
    "el_CY",
    "tr_CY",
    "cs_CZ",
    "en_CZ",
    "da_DK",
    "en_DK",
    "fo_DK",
    "en_DG",
    "ar_DJ",
    "fr_DJ",
    "so_DJ",
    "en_DM",
    "es_DM",
    "es_DO",
    "qu_EC",
    "es_EC",
    "ar_EG",
    "es_SV",
    "fr_GQ",
    "pt_GQ",
    "es_GQ",
    "ar_ER",
    "byn_ER",
    "en_ER",
    "gez_ER",
    "tig_ER",
    "ti_ER",
    "en_EE",
    "et_EE",
    "en_SZ",
    "ss_SZ",
    "am_ET",
    "gez_ET",
    "om_ET",
    "so_ET",
    "ti_ET",
    "wal_ET",
    "en_150",
    "en_FK",
    "es_FK",
    "fo_FO",
    "en_FJ",
    "en_FI",
    "fi_FI",
    "smn_FI",
    "se_FI",
    "sv_FI",
    "br_FR",
    "ca_FR",
    "co_FR",
    "en_FR",
    "fr_FR",
    "oc_FR",
    "pt_FR",
    "gsw_FR",
    "fr_GF",
    "es_GF",
    "fr_PF",
    "fr_GA",
    "en_GM",
    "ff_GM",
    "ka_GE",
    "os_GE",
    "ksh_DE",
    "en_DE",
    "de_DE",
    "nds_DE",
    "dsb_DE",
    "hsb_DE",
    "ak_GH",
    "en_GH",
    "ee_GH",
    "ff_GH",
    "gaa_GH",
    "ha_GH",
    "en_GI",
    "en_GR",
    "el_GR",
    "da_GL",
    "kl_GL",
    "es_GL",
    "en_GD",
    "es_GD",
    "fr_GP",
    "es_GP",
    "en_GU",
    "es_GT",
    "en_GG",
    "ff_GW",
    "pt_GW",
    "fr_GN",
    "ff_GN",
    "kpe_GN",
    "nqo_GN",
    "en_GY",
    "es_GY",
    "fr_HT",
    "es_HT",
    "es_HN",
    "yue_HK",
    "zh_HK",
    "en_HK",
    "en_HU",
    "hu_HU",
    "en_IS",
    "is_IS",
    "as_IN",
    "bn_IN",
    "brx_IN",
    "ccp_IN",
    "en_IN",
    "gu_IN",
    "hi_IN",
    "kn_IN",
    "ks_IN",
    "kok_IN",
    "ml_IN",
    "mni_IN",
    "mr_IN",
    "ne_IN",
    "or_IN",
    "pa_IN",
    "sa_IN",
    "sat_IN",
    "ta_IN",
    "te_IN",
    "bo_IN",
    "ur_IN",
    "en_ID",
    "id_ID",
    "jv_ID",
    "ckb_IR",
    "mzn_IR",
    "lrc_IR",
    "fa_IR",
    "ar_IQ",
    "ckb_IQ",
    "lrc_IQ",
    "syr_IQ",
    "en_IE",
    "ga_IE",
    "en_IM",
    "gv_IM",
    "ar_IL",
    "en_IL",
    "he_IL",
    "ca_IT",
    "en_IT",
    "fur_IT",
    "de_IT",
    "it_IT",
    "sc_IT",
    "scn_IT",
    "en_JM",
    "en_JP",
    "ja_JP",
    "en_JE",
    "ar_JO",
    "kk_KZ",
    "ru_KZ",
    "ebu_KE",
    "en_KE",
    "guz_KE",
    "kln_KE",
    "kam_KE",
    "ki_KE",
    "luo_KE",
    "luy_KE",
    "mas_KE",
    "mer_KE",
    "om_KE",
    "saq_KE",
    "so_KE",
    "sw_KE",
    "dav_KE",
    "teo_KE",
    "en_KI",
    "sq_XK",
    "sr_XK",
    "ar_KW",
    "ky_KG",
    "ru_KG",
    "lo_LA",
    "es_419",
    "en_LV",
    "lv_LV",
    "ar_LB",
    "en_LS",
    "st_LS",
    "en_LR",
    "ff_LR",
    "kpe_LR",
    "vai_LR",
    "ar_LY",
    "de_LI",
    "gsw_LI",
    "en_LT",
    "lt_LT",
    "en_LU",
    "fr_LU",
    "de_LU",
    "lb_LU",
    "pt_LU",
    "zh_MO",
    "en_MO",
    "pt_MO",
    "en_MG",
    "fr_MG",
    "mg_MG",
    "en_MW",
    "ny_MW",
    "en_MY",
    "ms_MY",
    "ta_MY",
    "dv_MV",
    "en_MV",
    "bm_ML",
    "fr_ML",
    "khq_ML",
    "ses_ML",
    "en_MT",
    "mt_MT",
    "en_MH",
    "fr_MQ",
    "es_MQ",
    "ar_MR",
    "fr_MR",
    "ff_MR",
    "en_MU",
    "fr_MU",
    "mfe_MU",
    "fr_YT",
    "en_MX",
    "es_MX",
    "en_FM",
    "ro_MD",
    "ru_MD",
    "fr_MC",
    "mn_MN",
    "en_ME",
    "sr_ME",
    "en_MS",
    "es_MS",
    "ar_MA",
    "tzm_MA",
    "fr_MA",
    "zgh_MA",
    "shi_MA",
    "mgh_MZ",
    "pt_MZ",
    "seh_MZ",
    "my_MM",
    "en_MM",
    "af_NA",
    "en_NA",
    "naq_NA",
    "en_NR",
    "ne_NP",
    "nl_NL",
    "en_NL",
    "nds_NL",
    "fy_NL",
    "fr_NC",
    "en_NZ",
    "mi_NZ",
    "es_NI",
    "fr_NE",
    "ff_NE",
    "ha_NE",
    "twq_NE",
    "dje_NE",
    "en_NG",
    "ff_NG",
    "ha_NG",
    "ig_NG",
    "kaj_NG",
    "kcg_NG",
    "yo_NG",
    "en_NU",
    "en_NF",
    "ko_KP",
    "sq_MK",
    "mk_MK",
    "en_MP",
    "en_NO",
    "se_NO",
    "nb_NO",
    "nn_NO",
    "ar_OM",
    "en_PK",
    "ps_PK",
    "pa_PK",
    "sd_PK",
    "ur_PK",
    "en_PW",
    "ar_PS",
    "es_PA",
    "en_PG",
    "gn_PY",
    "es_PY",
    "qu_PE",
    "es_PE",
    "ceb_PH",
    "en_PH",
    "fil_PH",
    "es_PH",
    "en_PN",
    "en_PL",
    "pl_PL",
    "en_PT",
    "pt_PT",
    "en_PR",
    "es_PR",
    "ar_QA",
    "fr_RE",
    "en_RO",
    "ro_RO",
    "ba_RU",
    "ce_RU",
    "cv_RU",
    "en_RU",
    "myv_RU",
    "os_RU",
    "ru_RU",
    "sah_RU",
    "tt_RU",
    "en_RW",
    "fr_RW",
    "rw_RW",
    "en_WS",
    "it_SM",
    "pt_ST",
    "ar_SA",
    "en_SA",
    "fr_SN",
    "ff_SN",
    "dyo_SN",
    "wo_SN",
    "en_RS",
    "sr_RS",
    "en_SC",
    "fr_SC",
    "en_SL",
    "ff_SL",
    "zh_SG",
    "en_SG",
    "ms_SG",
    "ta_SG",
    "nl_SX",
    "en_SX",
    "es_SX",
    "en_SK",
    "sk_SK",
    "en_SI",
    "sl_SI",
    "en_SB",
    "ar_SO",
    "so_SO",
    "af_ZA",
    "en_ZA",
    "nso_ZA",
    "nr_ZA",
    "st_ZA",
    "ss_ZA",
    "ts_ZA",
    "tn_ZA",
    "ve_ZA",
    "xh_ZA",
    "zu_ZA",
    "en_KR",
    "ko_KR",
    "ar_SS",
    "en_SS",
    "nus_SS",
    "ast_ES",
    "eu_ES",
    "ca_ES",
    "en_ES",
    "gl_ES",
    "es_ES",
    "si_LK",
    "ta_LK",
    "fr_BL",
    "es_BL",
    "en_SH",
    "en_KN",
    "es_KN",
    "en_LC",
    "es_LC",
    "fr_MF",
    "es_MF",
    "fr_PM",
    "es_PM",
    "en_VC",
    "es_VC",
    "ar_SD",
    "en_SD",
    "nl_SR",
    "es_SR",
    "nb_SJ",
    "en_SE",
    "se_SE",
    "sv_SE",
    "en_CH",
    "fr_CH",
    "de_CH",
    "it_CH",
    "pt_CH",
    "rm_CH",
    "gsw_CH",
    "wae_CH",
    "ar_SY",
    "fr_SY",
    "syr_SY",
    "zh_TW",
    "en_TW",
    "trv_TW",
    "tg_TJ",
    "asa_TZ",
    "bez_TZ",
    "en_TZ",
    "lag_TZ",
    "jmc_TZ",
    "kde_TZ",
    "mas_TZ",
    "rof_TZ",
    "rwk_TZ",
    "sbp_TZ",
    "ksb_TZ",
    "sw_TZ",
    "vun_TZ",
    "en_TH",
    "th_TH",
    "pt_TL",
    "ee_TG",
    "fr_TG",
    "en_TK",
    "en_TO",
    "to_TO",
    "en_TT",
    "es_TT",
    "ar_TN",
    "fr_TN",
    "en_TR",
    "ku_TR",
    "tr_TR",
    "tk_TM",
    "en_TC",
    "es_TC",
    "en_TV",
    "en_UM",
    "en_VI",
    "es_VI",
    "cgg_UG",
    "en_UG",
    "lg_UG",
    "nyn_UG",
    "xog_UG",
    "sw_UG",
    "teo_UG",
    "en_UA",
    "ru_UA",
    "uk_UA",
    "ar_AE",
    "en_AE",
    "kw_GB",
    "en_GB",
    "gd_GB",
    "cy_GB",
    "chr_US",
    "en_US",
    "haw_US",
    "lkt_US",
    "es_US",
    "es_UY",
    "uz_UZ",
    "en_VU",
    "fr_VU",
    "it_VA",
    "es_VE",
    "vi_VN",
    "fr_WF",
    "ar_EH",
    "ar_001",
    "en_001",
    "eo_001",
    "io_001",
    "ia_001",
    "jbo_001",
    "yi_001",
    "ar_YE",
    "bem_ZM",
    "en_ZM",
    "en_ZW",
    "nd_ZW",
    "sn_ZW",
] as const;
export const KnownLanguageCodes = LanguageCodes as unknown as string[];
type LanguageCode = typeof LanguageCodes[number]

export default LanguageCode;