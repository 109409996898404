import React from 'react';
import './LanguageSwitch.css';
import LanguageButton from "./LanguageButton";
import {Dropdown} from "react-bootstrap";

const _ = require('lodash');


/**
 * This component is a group of buttons that allows the switching between defined languages.
 *
 * If not 2 or more languages are passed to this component, null is returned.
 *
 * It uses LanguageButton for rendering the individual buttons.
 *
 * @param languages the possible languages. If fewer than 2 languages are passed, nothing is rendered.
 * @param currentLanguage the currently selected language.
 * @param setLanguage method to update the language
 * @param {String} defaultLanguage the default language that should be selected if no currentLanguage is set.
 * @return {JSX.Element|null}
 */
function LanguageSwitch({languages, currentLanguage, setLanguage, defaultLanguage = "de_DE"}) {
    if (!_.isArray(languages) || languages.length < 2) {
        // if languages property is not set, or only one language exists, do not display the button
        return null;
    }

    // set default language
    if (!currentLanguage) {
        currentLanguage = defaultLanguage
    }

    const dropdownOptions = languages
        .filter(language => language !== currentLanguage)
        .map(language => <Dropdown.Item>
            <LanguageButton
                key={language}
                language={language}
                setLanguage={setLanguage}/>
        </Dropdown.Item>)

    return (
        <Dropdown>
            <Dropdown.Toggle currentLanguage={currentLanguage} variant={'outline-secondary'}>
                <LanguageButton language={currentLanguage} setLanguage={() => {
                }}/>
            </Dropdown.Toggle>
            <Dropdown.Menu align={'end'} className={'LanguageSwitch'}>
                {dropdownOptions}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LanguageSwitch;