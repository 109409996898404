import {LanguageCodedKeyedObjectWithDefault} from "../common";

export const QuestionType = {
    YES_NO: 'yes/no',
    CHOOSE_ONE_DROPDOWN: 'ChoseOneDropdown',
    CHOOSE_ONE_RADIO_BUTTON: 'ChooseOneRadioButton',
    MULTIPLE_CHOICE_CHECKBOX: 'MultipleChoiceCheckbox',
    FREE_TEXT: 'FreeText',
} as const;
export type QuestionTypeTyping = typeof QuestionType[keyof typeof QuestionType]

export interface Question {
    _id: string;
    _key: string;
    type: QuestionTypeTyping
    mainQuestion: LanguageCodedKeyedObjectWithDefault<string>;
    moreDetails: LanguageCodedKeyedObjectWithDefault<string>;
    isLoading: boolean;
    isPristine?: boolean;
    show: boolean;
    prio?: string;
}