import {ChooseOneQuestion} from "../../../models/questions/ChooseOneQuestion";
import {SupportiveBackendConnector} from "../../../supportive-backend/SupportiveBackendConnector";

const useChooseOneDeSelectionFunction = (question: ChooseOneQuestion, setStatus: (nodeID: string, newStatus: number, performNodeUpdate: boolean) => Promise<any>) => {
    const {createAction} = SupportiveBackendConnector.useCreateActionApi();

    const deselectAll = async (triggerRefetchOnLast = false) => {
        const currentlyNotNegatedSelectedOptions = question.options
            .filter(x => x.state !== -1);

        // Forcing an update without effects to ensure proper updates; not very nice tbh
        if(currentlyNotNegatedSelectedOptions.length === 0) {
            currentlyNotNegatedSelectedOptions.push(question.options[0])
        }

        const lastOptionToDeselect = currentlyNotNegatedSelectedOptions.pop();
        for(const singleOption of currentlyNotNegatedSelectedOptions) {
            await setStatus(singleOption._id, -1, false);
        }

        if(lastOptionToDeselect) {
            await setStatus(lastOptionToDeselect._id, -1, triggerRefetchOnLast);
        }

    }

    const setSelectionTo = (questionId) => {
        createAction({
            actionType: 'select-aggregated-question',
            actionPayload: {
                selectedId: questionId,
                aggregateId: question._id,
                availableOptions: question.options.map(x =>x._id),
            },
        })
        return deselectAll().then(() => {
            setStatus(questionId, 1, true);
        })
    };

    return {
        deselectAll,
        setSelectionTo,
    }
}

export default useChooseOneDeSelectionFunction;