/**
 * Fetches every node from a specific graph. The juriCoreSID is actualised via the setter method.
 * The callback-function is called with the response
 *
 * @param {String} baseUrl: The base URL of the JuriCore instance
 * @param {String=null} juriCoreSID: the current juriCoreSID or null
 * @param {Function} setJuriCoreSID: setter function for juriCoreSID
 * @param {String} graphName: name of the graph
 * @param {Function} callback: the callback function. It is called after calling the juriCore responded
 *                              with the nodes as an array as argument
 * @param {AbortController} abortController: controller that is used to signal the fetch request to stop/abort.
 * @param {string} token: the current auth token
 */
import JuriCoreError from "../errors/JuriCoreError";
import {SetterFunction} from "../../models/common";
import {Graph} from "../../models/graph/Graph";
import {replaceIframeWithJunitIframe} from "../../util/replace-iframe-with-junit-iframe";

function getGraph(baseUrl: string, juriCoreSID: string, setJuriCoreSID: SetterFunction<string>, graphName: string, callback: (graph: Graph) => void, abortController: AbortController, token: string) {
    let url = new URL(`${baseUrl}/graphs/${graphName}`);

    return fetch(url.toString(), {
        method: 'GET',
        signal: abortController.signal,
        headers: {
            'Content-Type': 'application/json',
            'X-JuriCORE-SID': juriCoreSID,
            'Authorization': `Bearer ${token}`
        }
    }).then(response => {
        if (!response.ok) throw new JuriCoreError(`error fetching nodes from ${graphName}`, response);
        setJuriCoreSID(response.headers.get('X-JuriCORE-SID'));
        response.json().then(result => {
            const junItIFrameReplacedResult = {
                ...result,
                nodes: result.nodes.map(singleNode => {
                    if(!singleNode.values?.question) {
                        return singleNode;
                    }

                    return {
                        ...singleNode,
                        values: {
                            ...singleNode.values,
                            question: replaceIframeWithJunitIframe(singleNode.values.question)
                        },
                    }
                })
            };
            callback(junItIFrameReplacedResult)
        });
    });
}

export default getGraph;