/**
 * Generates an object with a key value pairing: countryCode => content.
 * It retrieves country code and key from a given object and a given prefix. If the prefix is "test" then the
 * it will recognize and includes keys on the object such as this: "test_de_DE", "test_en_GB"
 *
 * It is a shallow function and does not work recursively.
 *
 * @param object The object in which countryCode-value pairs should be extracted from.
 * @param prefix The prefix to assume.
 * @returns A object with countryCode value pairing. When none is found an empty object is returned.
 */
import {LanguageCodeKeyedObject} from "../../../models/common";
import LanguageCode, {KnownLanguageCodes} from "../../../models/LanguageCode";

const getAvailableLanguageCodes = (object: object, prefix: string): LanguageCodeKeyedObject<any> => {
    const result: LanguageCodeKeyedObject<any> = {};

    const groupName = 'languageCode'
    const regex = new RegExp(`^${prefix}_(?<${groupName}>[a-zA-Z]{2,3}_[a-zA-Z0-9]{2,3})$`);
    for(const singleKey in object) {
        const found = regex.exec(singleKey)
        if(found !== null && found !== undefined && KnownLanguageCodes.includes(found.groups[groupName])) {
            const languageCode = found.groups[groupName] as LanguageCode;
            result[languageCode] = object[singleKey];
        }
    }

    return result;
};

export default getAvailableLanguageCodes;