import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './jun-styling.scss'
import App from './App';
import * as serviceWorker from './serviceWorker';
import SSOProtected from "./auth/SSOProtected/SSOProtected";
import {AuthProvider} from "react-oidc-context";
import {oidcConfig} from "./auth/oidcConfig";

ReactDOM.render(
  <React.StrictMode>
      <AuthProvider {...oidcConfig}>
          <SSOProtected>
              <App />
          </SSOProtected>
      </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
