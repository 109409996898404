import {useSupportiveBackendClient} from "./useSupportiveBackendClient";
import {useCallback, useContext} from "react";
import {SupportiveBackendContext} from "../SupportiveBackendContext";
import {CreateSessionDto} from "../models/create-session.dto";
import IsMobileContext from "../../contexts/IsMobileContext";
import {useIsProjectMember} from "../../util/LocalStorage";

export const useCreateSessionApi = ()  => {
    const {client} = useSupportiveBackendClient();
    const {setSessionID} = useContext(SupportiveBackendContext);
    const isMobile = useContext(IsMobileContext);
    const [isProjectMember] = useIsProjectMember();

    const createNewSession = useCallback((data: CreateSessionDto) => {
        client.interceptors.response.use((response) => {
            setSessionID(response.data.id);
        });

        const payload = {
            ...data,
            isProbablyMobileDevice: data.isProbablyMobileDevice ?? isMobile,
        };

        if(isProjectMember) {
            payload.isProjectMember = isProjectMember;
        }

        //return client.post('sessions', payload);
    }, [client, isMobile, isProjectMember, setSessionID])

    return {createNewSession};
}