import {FaSpinner} from "react-icons/fa";
import React from "react";

type IProps = {[key: string]: any} & {
    show?: boolean;
}

export const LoadingIndicator = ({show, ...props}: IProps) => {
    if(show === false) {
        return null;
    }

    return <FaSpinner {...props} className={'rotating'}/>
}