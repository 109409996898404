export const NodeTypes = {
    QUESTION: "Sachverhaltsinformation",
    GROUP: "Gruppe",
    ANSWER: "Ergebnis",
};

export const EdgeTypes = {
    HAS_MEMBER: 'has_member',
};

export const Status = {
    YES: 1,
    UNKNOWN: 0,
    NO: -1,
};