import React, {useContext, useEffect, useRef, useState} from 'react';
import './Answers.css'
import NoAnswer from "./NoAnswer/NoAnswer";
import Answer from "./Answer/Answer";
import Card from "react-bootstrap/Card";
import {FaRegLightbulb} from "react-icons/fa";
import LanguageContext from "../../contexts/LanguageContext";
import getText from "../../util/texts/getText";
import {Accordion} from "react-bootstrap";
import JunBotSettingsContext from "../../contexts/JunBotSettingsContext";

const _ = require('lodash');

function Answers({nodes}) {
    const [language, ] = useContext(LanguageContext);
    const [activeAnswers, setActiveAnswers] = useState([])
    const [, setShowNewSolutionHint] = useState(false)
    const lastAnswers = useRef([])
    const junBotSettings = useContext(JunBotSettingsContext);

    useEffect(() => {
        setActiveAnswers(_.filter(nodes, node => node.values.status === 1));
    }, [nodes]);

    useEffect(() => {
        if (_.difference(activeAnswers, lastAnswers.current).length !== 0) {
            //console.log("NEW SOLUTION", activeAnswers)
            setShowNewSolutionHint(true);
        }
        lastAnswers.current = activeAnswers;
    }, [activeAnswers])

    // check which answers should be expanded as default
    const defaultExpandedAnswers = activeAnswers
        .filter(answer => !(answer.values.overwriteCollapseAnswers ?? junBotSettings.collapseAnswers ?? true))
        .map(answer => answer._id)

    return (<div className={'JunBots-Answers'}>
        {activeAnswers.length === 0 && <NoAnswer/>}
        {activeAnswers.length !== 0 &&
            <Card className={'JunBots-Answer'}>
                <Card.Title className={'JunBots-Answer-GeneralTitle'}>
                    <h2 className='JunBots-Answer-Header'>
                        <FaRegLightbulb/> Thank you for your time
                    </h2>
                </Card.Title>
                <br/>
                <Accordion alwaysOpen defaultActiveKey={defaultExpandedAnswers}>
                    {activeAnswers.map(answer => {
                        return <Answer key={answer._id}
                                       node={answer}
                                />
                    })}
                </Accordion>
            </Card>
        }
    </div>)
}

export default Answers