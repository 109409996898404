import {GraphNode} from "../../models/graph/GraphNode";
import {Question, QuestionType} from "../../models/questions/Question";
import {ChooseOneQuestion, Option} from "../../models/questions/ChooseOneQuestion";

export const createTextFromInformation = (questions: Question[], rawNodes: GraphNode[]) => {
    let rawNodesObject: Record<string, GraphNode> = {}
    rawNodes.forEach(rawNode => {
        rawNodesObject[rawNode._id] = rawNode
    })

    let text = ""

    questions.forEach(question => {
        switch (question.type) {
            case QuestionType.FREE_TEXT:
            case QuestionType.YES_NO:
                const rawQuestion = rawNodesObject[question._id]
                text += `
${rawQuestion.values['name']}:
    Status:   ${rawQuestion.values['status']}
    Freitext: ${rawQuestion.values['text']}\n\n
`
                break
            case QuestionType.MULTIPLE_CHOICE_CHECKBOX:
            case QuestionType.CHOOSE_ONE_RADIO_BUTTON:
            case QuestionType.CHOOSE_ONE_DROPDOWN:
                const groupNode = question as ChooseOneQuestion
                text += groupNode.mainQuestion.default + ':\n'

                groupNode.options.forEach((option: Option) => {
                    if (option.state === 1) {
                        text += `     - ${option.selectionText.default}\n`
                    }
                })
                text += '\n\n'
        }
    })

    return text
}