import React from 'react';
import Stack from "react-bootstrap/Stack";
import {CountryFlag} from "../CountryFlag/CountryFlag";

function CountryFlags({languages, ...props}) {
    if (!languages) {
        return null;
    }

    const flags = languages.map(language => {
        return <CountryFlag languageCode={language} {...props}/>
    })

    return (
        <Stack direction={'horizontal'} gap={1}>
            {flags}
        </Stack>
    )
}

export default CountryFlags;