/**
 * Increases the number of visited user counter for this graph by 1.
 *
 * @param {String} baseUrl: the base url
 * @param {String} junBotGraphName: the graphName of the requested junBot settings
 * @param {string} token: the current auth token
 */
function increaseVisitCount(baseUrl, junBotGraphName, token) {
    let url = new URL(`${baseUrl}/graphs/${junBotGraphName}/increase-counter`)
    /*
    fetch(url.toString(), {
        method: 'POST'
    }).then(
        // do nothing
    )
     */
}

export default increaseVisitCount