import getAvailableLanguageCodes from "../../getAvailableLanguageCodes/getAvailableLanguageCodes";
import {QuestionType, QuestionTypeTyping} from "../../../../models/questions/Question";
import {GraphNode} from "../../../../models/graph/GraphNode";
import {YesNoQuestion} from "../../../../models/questions/YesNoQuestion";
import {FreeTextQuestion} from "../../../../models/questions/FreeTextQuestion";

/**
 * This functions converts a question node (label "Sachverhaltsinformation") to an abstracted question object decoupling node and question structure.
 * It assumes a correct question node and does not employ any error handling.
 *
 * @param questionNode The question node which should be converted to a question.
 * @returns {{isLoading: (boolean|*), mainQuestion: (*&{default}), moreDetails: (*&{default: *}), answered: boolean, show, options: {isSelected: boolean, _id: *, _key: *, selectionText: *&{default: *}}[], _id: (string|String|*), _key: (string|*), type: (*|string), prio}}
 */
const convertQuestionNodeToQuestionObject = (questionNode: GraphNode): (YesNoQuestion | FreeTextQuestion) => {
    let type: QuestionTypeTyping = QuestionType.YES_NO
    if (questionNode.labels.includes('free_text')) {
        type = QuestionType.FREE_TEXT
    }
    return {
        _id: questionNode._id,
        _key: questionNode._key,
        type: type,
        mainQuestion: {
            default: questionNode.values.name,
            ...getAvailableLanguageCodes(questionNode.values, 'name')
        },
        moreDetails: {
            default: questionNode.values.question,
            ...getAvailableLanguageCodes(questionNode.values, 'question')
        },
        status: questionNode.values.status,
        isLoading: false,
        show: questionNode.values.show,
        prio: questionNode.values.prio,
    }
};

export default convertQuestionNodeToQuestionObject;