import React, {useContext, useEffect, useState} from 'react';
import './Footer.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Connector from "../JuriCore/Connector";
import LanguageContext from "../contexts/LanguageContext";
import Container from "react-bootstrap/Container";
import getText from "../util/texts/getText";
import junLogo from "./JUN_logo2.png";

function Footer() {
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [juriCoreInfo, setJuriCoreInfo] = useState({});
    const [language,] = useContext(LanguageContext);

    useEffect(() => {
        shouldUpdate && Connector.getJuriCoreInfo(setJuriCoreInfo);
        setShouldUpdate(false)
    }, [shouldUpdate]);

    return (
        <Container fluid={true}>
            <Row>
                <Col className={'JunBot-Footer-middle Footer-Information'}>
                    <Row>
                        <Col md={'auto'}>
                            <a href={'https://www.junit.de'} target={'_blank'} rel={'noreferrer noopener'}>
                                <img className={'Jun-Logo'} src={junLogo} alt={'Jun'}/>
                            </a>
                        </Col>
                        <Col md={'auto'} style={{marginTop: 'auto'}}>
                            <a className={'Footer-Information'}
                               href={'https://www.junit.de/2020/datenschutzerklaerung/'}
                               target={'_blank'} rel={'noreferrer noopener'}>
                                {getText("privacyPolicy", language)}
                            </a>
                            {" " + getText("noteOnlyGerman", language)}
                        </Col>
                        <Col md={'auto'}>
                            <a className={'Footer-Information'}
                               href={'https://www.junit.de/2020/impressum/'}
                               target={'_blank'} rel={'noreferrer noopener'}>
                                {getText("imprint", language)}
                            </a>
                            {" " + getText("noteOnlyGerman", language)}
                        </Col>
                        <Col md={'auto'}>
                            <a className={'Footer-Information'}
                               href={'https://www.junit.de/2020/elementor-4003/'}
                               target={'_blank'} rel={'noreferrer noopener'}>
                                {getText("contact", language)}
                            </a>
                            {" " + getText("noteOnlyGerman", language)}
                        </Col>
                    </Row>
                </Col>
                <Col className={'JunBot-Footer-right'} md={'auto'}>
                    <div className={'JunBot-Footer-Version'}>
                        v{process.env.REACT_APP_VERSION}@{juriCoreInfo.version}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer