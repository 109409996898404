/**
 * Fetches all graphNames of available junBots
 *
 * @param {String} baseUrl: the base url
 * @param {Function} callback: functions is invoked with an array of the graphNames on success
 */
import JuriCoreError from "../errors/JuriCoreError";

function getJunBotGraphNames(baseUrl, callback, token) {
    let url = new URL(`${baseUrl}/graphs`);

    fetch(url.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }).then(response => {
        if (!response.ok) throw new JuriCoreError('error fetching graphNames of junBots', response);
        response.json().then(graphs => {
            callback(graphs)
        })
    })
}

export default getJunBotGraphNames
