import React, {useContext, useEffect, useRef, useState} from 'react';
import './Disclaimer.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import JunBotMarkdown from "../../JunbotMarkdown/JunBotMarkdown";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io"
import LanguageContext from "../../../contexts/LanguageContext";
import getText from "../../../util/texts/getText";
import {Col} from "react-bootstrap";
import useElementDimensions from "../../../hooks/useElementDimensions";

interface IProps {
    startExpanded?: boolean;
    onlyContent?: boolean;
}

/**
 * Component to display the disclaimer.
 * Has a headline and can be expanded by click on the headline.
 *
 * @param startExpanded: whether the component should start in the expanded state
 * @param onlyContent: whether the headline should be hidden and only show the content (without expandability function)
 * @return {*}
 * @constructor
 */
const Disclaimer = ({startExpanded, onlyContent}: IProps) => {
    const [showContent, setShowContent] = useState(startExpanded);
    const [contentText, setContentText] = useState('');
    const headlineRef = useRef();
    const dimensions = useElementDimensions(headlineRef);
    const [language, ] = useContext(LanguageContext);
    const headlineText = getText("disclaimerHeadline", language);

    //fetch Markdown file for content
    useEffect(() => {
        getText("disclaimerContent", language, setContentText);
    }, [contentText, language]);

    const toggleShowContent = () => setShowContent(!showContent);

    if (onlyContent) {
        return (
            <Container className={'JunBots-Disclaimer'}>
                <Row className={'JunBots-Disclaimer-Content'}>
                    <JunBotMarkdown>{contentText}</JunBotMarkdown>
                </Row>
            </Container>
        )
    }

    return <div></div>

    return (
        <Container className={'JunBots-Disclaimer'}>
            <Row
                onClick={toggleShowContent}
                className={'JunBots-Disclaimer-Headline expandable'}>
                <Container className={'centering-container'}>
                    <Col md={'auto'} ref={headlineRef}>
                        {showContent ? <IoIosArrowUp/> : <IoIosArrowDown/>}
                        <b> {headlineText}</b>
                    </Col>
                </Container>
            </Row>
            {
                showContent &&
                <Row className={'JunBots-Disclaimer-Content'}>
                    <Container className={'centering-container'}>
                        <Col style={{maxWidth: dimensions.width}}>
                            <JunBotMarkdown>{contentText}</JunBotMarkdown>
                        </Col>
                    </Container>
                </Row>
            }
        </Container>
    )
}

export default Disclaimer;