import getQuestionsByGroup from "../../getQuestionsByGroup";
import getAvailableLanguageCodes from "../../getAvailableLanguageCodes/getAvailableLanguageCodes";
import {Graph} from "../../../../models/graph/Graph";
import {GraphNode, SelectionType, SelectionTypeTyping} from "../../../../models/graph/GraphNode";
import {QuestionType} from "../../../../models/questions/Question";
import {
    ChooseOneQuestion,
    Option,
    QuestionTypesChooseOneQuestion
} from "../../../../models/questions/ChooseOneQuestion";

/**
 * This functions converts a group node (label "Gruppe") to an abstracted question object decoupling node and question structure. The options of the group are
 * question nodes connected by edges of type "has_member". It is assumed that the given node is a valid group node and subsequent question nodes as well as the graph are also correct.
 * No error handling is employed.
 *
 * @param graph A correct graph or subgraph containing the group node and the edges relevant for the overall question.
 * @param group The group node which should be converted to a question.
 * @returns {{isLoading: (boolean|*), mainQuestion: (*&{default}), moreDetails: (*&{default: *}), answered: boolean, show, options: {isSelected: boolean, _id: *, _key: *, selectionText: *&{default: *}}[], _id: (string|String|*), _key: (string|*), type: (*|string), prio}}
 */
const convertGroupNodeToQuestionObject = (graph: Graph, group: GraphNode): ChooseOneQuestion => {

    const underlyingQuestionNodes = getQuestionsByGroup(graph, group._id);

    const selectionOptions: Option[] = underlyingQuestionNodes
        .filter(singleNode => singleNode.values.show)
        .map(convertQuestionNodeToOption);

    return {
        _id: group._id,
        _key: group._key,
        type: getQuestionTypeFromSelectionType(group.values.selectionType) ?? QuestionType.CHOOSE_ONE_DROPDOWN,
        mainQuestion: {
            default: group.values.name,
            ...getAvailableLanguageCodes(group.values, 'name')
        },
        moreDetails: {
            default: group.values.question,
            ...getAvailableLanguageCodes(group.values, 'question')
        },
        answered: selectionOptions.some(x => x.state === 1),
        isLoading: group.isLoading ?? false,
        show: group.values.show,
        prio: group.values.prio,
        options: selectionOptions
    };
};

const convertQuestionNodeToOption = (singleSelectionOption: GraphNode) => {
    const resultOption: Option = {
        _id: singleSelectionOption._id,
        _key: singleSelectionOption._key,
        selectionText: {
            default: singleSelectionOption.values.selectionText ?? singleSelectionOption.values.name,
            ...getAvailableLanguageCodes(singleSelectionOption.values, 'name'),
            ...getAvailableLanguageCodes(singleSelectionOption.values, 'selectionText')
        },
        state: singleSelectionOption.values.status
    }

    if(singleSelectionOption.values.moreSelectionText || singleSelectionOption.values.question) {
        resultOption.moreDetails = {
            default: singleSelectionOption.values.moreSelectionText ?? singleSelectionOption.values.question,
            ...getAvailableLanguageCodes(singleSelectionOption.values, 'question'),
            ...getAvailableLanguageCodes(singleSelectionOption.values, 'moreSelectionText')
        }
    }

    return resultOption;
}

const getQuestionTypeFromSelectionType = (selectionType?: SelectionTypeTyping): QuestionTypesChooseOneQuestion => {
    if(!selectionType) {
        return QuestionType.CHOOSE_ONE_DROPDOWN;
    }
    switch (selectionType) {
        case SelectionType.DROPDOWN:
            return QuestionType.CHOOSE_ONE_DROPDOWN;
        case SelectionType.RADIO_BUTTON:
            return QuestionType.CHOOSE_ONE_RADIO_BUTTON;
        case SelectionType.CHECKBOX:
            return QuestionType.MULTIPLE_CHOICE_CHECKBOX;
        default:
            console.warn('Unknown selection type. Defaulting to dropdown');
    }

    return QuestionType.CHOOSE_ONE_DROPDOWN;
}

export default convertGroupNodeToQuestionObject;