import {useCallback, useState} from "react";

export interface IReturn<D extends any, PF extends (...parameters: any[]) => Promise<D>> {
    wrappedPromise: PF,
    pending: boolean,
    error: boolean,
    data: D
}

export const useWrappedPromise = <D extends any, PF extends (...parameters: any[]) => Promise<D>>(promiseFunction: PF): IReturn<D, PF> => {
    const [pending, setPending] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<any>(undefined);

    const wrappedPromise: PF = useCallback((...args: any[]) => {
        setPending(true);
        setError(false);
        const resultOfInitialPromise = promiseFunction.apply({}, args) as Promise<any>;

        resultOfInitialPromise
            .then((newData) => {
                setData(newData);
            })
            .catch(() => {
                setError(true);
            }).finally(() => {
                setPending(false);
        });

        return resultOfInitialPromise;
    }, [promiseFunction, setData, setPending, setError]) as PF;

    return {
        wrappedPromise,
        pending,
        error,
        data
    };
}