import {Question, QuestionType} from "../../models/questions/Question";
import {GraphNode} from "../../models/graph/GraphNode";
import {ChooseOneQuestion, Option} from "../../models/questions/ChooseOneQuestion";

export const extractGivenInformation = (questions: Question[], rawNodes: GraphNode[]) => {
    let rawNodesObject: Record<string, GraphNode> = {}
    rawNodes.forEach(rawNode => {
        rawNodesObject[rawNode._id] = rawNode
    })

    // type single question: GraphNode
    // type group question:  [GraphNode, GraphNode[]] => first argument ist group question, second is array of selected options
    const rawQuestions: (GraphNode | [GraphNode, GraphNode[]])[] = []
    questions.forEach(question => {
        switch (question.type) {
            case QuestionType.FREE_TEXT:
            case QuestionType.YES_NO:
                const rawQuestion = rawNodesObject[question._id]
                rawQuestions.push(rawQuestion)
                break
            case QuestionType.MULTIPLE_CHOICE_CHECKBOX:
            case QuestionType.CHOOSE_ONE_RADIO_BUTTON:
            case QuestionType.CHOOSE_ONE_DROPDOWN:
                const groupNode = question as ChooseOneQuestion
                const rawGroupNode = rawNodesObject[groupNode._id]
                const rawSelectedOptions: GraphNode[] = []

                groupNode.options.forEach((option: Option) => {
                    rawSelectedOptions.push(rawNodesObject[option._id])
                })

                rawQuestions.push([rawGroupNode, rawSelectedOptions])
        }
    })

    return rawQuestions
}