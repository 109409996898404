import React, {useContext, useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './ViewBot.css';
import JunBotMarkdown from "../../JunbotMarkdown/JunBotMarkdown";
import getText from "../../../util/texts/getText";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {FaArrowRight} from "react-icons/fa";

import Connector from "../../../JuriCore/Connector";
import _ from "lodash";
import CountryFlags from "../../misc/CountryFlags/CountryFlags";
import LanguageContext from "../../../contexts/LanguageContext";
import Node from "../../../JuriCore/Node";
import {useAuth} from "react-oidc-context";

function ViewBot({junBotGraphName}) {
    const [junBotSettings, setJunBotSettings] = useState({});
    const [currentLanguage,] = useContext(LanguageContext);
    const auth = useAuth();

    useEffect(() => {
        Connector.getJunBotSettings(junBotGraphName, setJunBotSettings, auth.user.access_token);
    }, [junBotGraphName]);

    if (!junBotSettings.show) return <span/>;

    let languages = ["de_DE"];
    if (junBotSettings.language) {
        languages = _.map(_.split(junBotSettings.language, ','), _.trim);
    }

    return (
        <Card className={'JunBots-Card'}>
            <Card.Body>
                <Card.Title>
                    <Row>
                        <Col md={'auto'}>
                            {Node.getProperty(junBotSettings, 'displayName', currentLanguage)}
                        </Col>
                        <Col>
                            <CountryFlags languages={languages} svg style={{fontSize: '12pt'}}/>
                        </Col>
                    </Row>
                </Card.Title>
                <Container className={'JunBots-ViewBot-Content'}>
                    <Row>
                        <Col className={'JunBots-Card-Body'}>
                            <JunBotMarkdown>
                                {Node.getProperty(junBotSettings, 'question', currentLanguage)}
                            </JunBotMarkdown>
                        </Col>
                        <Col md={'auto'}>
                            <Link to={`/junbots/${junBotGraphName}`}>
                                <Button variant={'danger'}><span>
                                    <FaArrowRight/> {getText("toJunbot", currentLanguage)}
                                </span></Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

export default ViewBot