import React, {useEffect, useState} from 'react';
import './Description.css'
import StartText from "./Description.md"
import JunBotMarkdown from "../../JunbotMarkdown/JunBotMarkdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function Description() {
    const [startText, setStartText] = useState('');


    useEffect(() => {
        fetch(StartText).then(response => response.text()).then(text => setStartText(text))
    }, [startText]);

    return (
        <Container className={'JunBots-Description'}>
            <Row>
                <JunBotMarkdown>{startText}</JunBotMarkdown>
            </Row>
        </Container>
    )
}

export default Description