import React, {useContext} from 'react';
import './Answer.css'
import Card from "react-bootstrap/Card";
import JunBotMarkdown from "../../JunbotMarkdown/JunBotMarkdown";
import ExpandableText from "../../misc/ExpandableText/ExpandableText";
import LanguageContext from "../../../contexts/LanguageContext";
import Node from "../../../JuriCore/Node";
import {FormControl} from "react-bootstrap";

function Answer({node}) {
    const [language,] = useContext(LanguageContext);

    let title = <Card.Subtitle>
        <div style={{color: node.values.color}}>
            <JunBotMarkdown>
                {Node.getProperty(node.values, "name", language)}
            </JunBotMarkdown>
        </div>
    </Card.Subtitle>

    const answerText = `
    Todos:
    
    1. ...
        [please enter ...]
    2. ...
    3. ...
    `

    const givenResultsText = `
    Given Results:
    
    - Source of Software: ${Node.getProperty(node.values, "source_of_software")}
    - Usage: ${Node.getProperty(node.values, "usage")}
    - Contracts: ${Node.getProperty(node.values, "contracts")}
    `

    return (
        <Card.Body className={'JunBots-Answer'}>
            Please review your answers above.
            Afterwards, press the submit button below, to send the given information to us.
        </Card.Body>
    )
}

export default Answer