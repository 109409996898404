import React, {useContext, useState} from 'react';
import './FreeTextQuestion.css';
import Card from "react-bootstrap/Card";
import LanguageContext from "../../../contexts/LanguageContext";
import LoadingButton from "../../misc/LoadingButton/LoadingButton";
import ExpandableText from "../../misc/ExpandableText/ExpandableText";
import QuestionCard from "../shared/QuestionCard/QuestionCard";
import {JuriCoreContext} from "../../../JuriCore/JuriCoreContext";
import Connector from "../../../JuriCore/Connector";
import {Form} from "react-bootstrap";
import {FaSpinner} from "react-icons/fa";
import {useAuth} from "react-oidc-context";

const _ = require('lodash');


/**
 * A component that represents a single question of a junBot.
 * It includes:
 *  - General question (name of question question)
 *  - Further question text (expandable via a button)
 *  - Yes/No-button to answer the question
 *
 * Each question is rendered as a Card.
 *
 * @typedef {object} question - object representing a single question
 * @property {String} _id - exclusive id of this question
 * @property {{name: String, question: String | null}} values - the values of the questions. Each question should have a name.
 *              A longer question text is optional.
 *
 * @param {FreeTextQuestion} question: The question which represents this question.
 * @param activeAccordionKey: The currently active key of the accordion handling each individual question.
 * @param setActiveAccordionKey: setter to update the accordion key on expanding of this question.
 * @return {JSX.Element}
 */
function FreeTextQuestion({question, activeAccordionKey, setActiveAccordionKey}) {
    const [language,] = useContext(LanguageContext);
    const juriContext = useContext(JuriCoreContext)
    const [freeText, setFreeText] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false)
    const auth = useAuth();

    const saveFreeText = (newText: String) => {
        console.log("save", newText)
        setLoading(true)
        Connector.setNodeFreeText(juriContext.sessionID, juriContext.setSessionID, question._id, newText, auth.user.access_token).then(() => {
            setLoading(false)
        }).catch((error) => {
            console.error(error)
            setLoading(false)
        })
    }

    // the main question that is always shown
    const mainQuestion = <Card.Title style={{margin: 0}}>
        {question.mainQuestion[language] ?? question.mainQuestion.default}
    </Card.Title>

    return (
        <QuestionCard>
            <div className={'FreeTextQuestion-content'}>
                <ExpandableText
                    context={{type: 'question', id: question._id}}
                    title={mainQuestion}
                    expandableContent={question.moreDetails[language] ?? question.moreDetails.default}
                    eventKey={question._key}
                    activeAccordionKey={activeAccordionKey}
                    setActiveAccordionKey={setActiveAccordionKey}
                    style={{gridAutoColumns: 'max-content'}}/>
                <div className={'JunBots-SingleQuestion-InputElement'}
                >
                    <OverlaySpinner active={isLoading}/>
                    <Form.Control as={'textarea'}
                                  value={freeText}
                                  onChange={event => setFreeText(event.currentTarget.value)}
                                  onBlur={() => saveFreeText(freeText)}
                                  placeholder={'write here...'}
                    />
                </div>
                <LoadingButton
                    variant={'danger'}
                    isLoading={isLoading}
                    onClick={() => saveFreeText(freeText)}
                >
                    Save
                </LoadingButton>
            </div>
        </QuestionCard>
    )
}

const OverlaySpinner = ({active}) => {
    if (!active) {
        return <></>
    }

    return <div className="overlay-spinner-dropdown">
        <FaSpinner className={'rotating'}/>
    </div>
}

export default FreeTextQuestion