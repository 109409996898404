import {SetterFunction} from "../models/common";
import React from "react";

export interface IJuriCoreContext {
    baseUrl: string;
    setBaseUrl: SetterFunction<string>;
    sessionID: string;
    setSessionID: SetterFunction<string>;
    connectionErrorOccurred: boolean;
    setConnectionErrorOccurred: SetterFunction<boolean>;
}

export const JuriCoreContext = React.createContext<IJuriCoreContext>(undefined)