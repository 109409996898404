/**
 * This class represents a general error during JuriCore interaction
 */
class JuriCoreError extends Error{
    /**
     * The general constructor
     *
     * @param message: a descriptive error message
     * @param response: the returned response on failed JuriCore interaction
     */
    constructor(message, response) {
        super();
        this.message = message;
        this.response = response;
    }
}

export default JuriCoreError