import React, {MouseEvent} from 'react';
import Button from "react-bootstrap/Button";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

type IProps = {
    [key: string]: any;
} & {
    isExpanded: boolean;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => any;
}


/**
 * A React component that represents a button to expand/collapse elements.
 * It uses forwardRef to allow the usage of refs, to allow the querying of the width of the button.
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly isExpanded?: *, readonly onClick?: *}> & React.RefAttributes<unknown>>}
 */
const ExpandButton = React.forwardRef<HTMLButtonElement, IProps>(({isExpanded, onClick, ...props}, ref) => {
    return (<Button onClick={onClick} size={'sm'} variant={null} ref={ref} {...props}>
        {
            isExpanded ? <IoIosArrowUp/> : <IoIosArrowDown/>
        }
    </Button>)
})

export default ExpandButton