import React, {useContext} from 'react';
import './Credits.css';
import PersonalDetails from "./PersonalDetails";
import Row from "react-bootstrap/Row";
import getText from "../../../util/texts/getText";
import LanguageContext from "../../../contexts/LanguageContext";

function Credits({junBotGraphName}) {
    const [language,] = useContext(LanguageContext);

    switch (junBotGraphName) {
        case "Help_for_Ukrainians":
        case "Help_for_Ukrainians_v2":
        case "Ukrainian_Refugee":
            return <div className={'JunBot-Credits'}>
                <Row>
                    <h4>
                        {getText("creditsHeadline", language)}
                    </h4>
                </Row>
                <Row className={'JunBot-Credits-PersonalDetails'}>
                    <PersonalDetails name={'Mara Ortler'}/>
                </Row>
                <Row className={'JunBot-Credits-PersonalDetails'}>
                    <PersonalDetails name={'Uni Wuerzburg'}/>
                </Row>
                <Row className={'JunBot-Credits-PersonalDetails'}>
                    <PersonalDetails name={'GLC'}/>
                </Row>
            </div>
        default:
            return <div/>
    }
}

export default Credits