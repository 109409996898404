import axios from "axios";
import axiosRetry from "axios-retry";
import {useAuth} from "react-oidc-context";

export const useStandardClient = (baseUrl: string) => {
    const auth = useAuth()
    const defaultHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.user.access_token}`
    };

    const axiosInstance = axios.create({baseURL: baseUrl, headers: defaultHeaders});

    axiosRetry(axiosInstance, {retries: 2, retryDelay: axiosRetry.exponentialDelay});

    return {standardClient: axiosInstance};

}