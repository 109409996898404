import React, {useContext} from 'react';
import './SelectJunBots.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ViewBots from "../../components/ViewBots/ViewBots";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import Disclaimer from "../../components/Texts/Disclaimer/Disclaimer";
import LanguageSwitch from "../../components/misc/LanguageSwitch/LanguageSwitch";
import LanguageContext from "../../contexts/LanguageContext";
import getText from "../../util/texts/getText";

function SelectJunBots(props) {
    const [language, setLanguage] = useContext(LanguageContext);
    const availableLanguages = ["de_DE", "en_GB"];

    return (
        <Container className={'Page'} fluid={true}>
            <div className={'Headline-Container'}>
                <div className={'Headline-Title md'}>
                    <h1>{getText("titleOverview", language)}</h1>
                </div>
                <div className={'Headline-right'}>
                    <LanguageSwitch languages={availableLanguages} currentLanguage={language}
                                    setLanguage={setLanguage}/>
                </div>
            </div>
            <Row>
                <Col className={'JunBots-Left'}/>
                <Col className={'JunBots-Middle Page-Content'} md={'auto'}>
                    <Row>
                        <Col>
                            <Disclaimer/>
                        </Col>
                    </Row>
                    <Row className={'JunBots-Select-BackButton'}>
                        <Col>
                            <Link to={'/'}>
                                <Button variant={'outline-secondary'}>
                                    {getText("furtherInformationJunbot", language)}
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row className={'JunBots-Select-ViewBots'}>
                        <Col><ViewBots {...props} /></Col>
                    </Row>
                </Col>
                <Col className={'JunBots-Right'}/>
            </Row>
        </Container>
    )
}

export default SelectJunBots