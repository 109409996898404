import Card from "react-bootstrap/Card";
import React, {useContext} from "react";
import ExpandableText from "../../misc/ExpandableText/ExpandableText";
import QuestionCard from "../shared/QuestionCard/QuestionCard";
import './SingleChooseOneRadioButtonQuestion.css';
import LanguageContext from "../../../contexts/LanguageContext";
import AdvancedRadioButton from "../../misc/AdvancedRadioButton/AdvancedRadioButton";
import {ChooseOneQuestion} from "../../../models/questions/ChooseOneQuestion";
import {FaSpinner} from "react-icons/fa";
import useChooseOneDeSelectionFunction from "../shared/useChooseOneDeSelectionFunction";
import {SupportiveBackendConnector} from "../../../supportive-backend/SupportiveBackendConnector";

interface IProps {
    question: ChooseOneQuestion;
    setStatus: (nodeID: string, newStatus: number, performNodeUpdate: boolean) => Promise<any>;
    activeAccordionKey: string;
    setActiveAccordionKey: (newKey: string) => any;
    keyId: string;
}

const SingleChooseOneRadioButtonQuestion = ({question, setStatus, activeAccordionKey, setActiveAccordionKey, keyId}: IProps) => {
    const [language] = useContext(LanguageContext);
    const {createAction} = SupportiveBackendConnector.useCreateActionApi();

    const mainQuestion = <Card.Title style={{margin: 0}}>
        {question.mainQuestion[language] ?? question.mainQuestion.default}
    </Card.Title>

    const {setSelectionTo} = useChooseOneDeSelectionFunction(question, setStatus);

    return <QuestionCard>
        <ExpandableText
            context={{type: 'question', id: question._id}}
            title={mainQuestion}
            expandableContent={question.moreDetails[language] ?? question.moreDetails.default}
            eventKey={question._key}
            activeAccordionKey={activeAccordionKey}
            setActiveAccordionKey={setActiveAccordionKey}
            style={{gridAutoColumns: 'auto'}}
        />

        <div className='RadioButton-Grouping'>
            <OverlaySpinner active={question.isLoading}/>
            {
                question.options.map((singleOption) => {
                    return <AdvancedRadioButton
                        name={question._id}
                        label={singleOption.selectionText[language] ?? singleOption.selectionText.default}
                        expandableText={singleOption.moreDetails ? singleOption.moreDetails[language] ?? singleOption.moreDetails.default : ''}
                        key={`SingleChooseOneWithNoneQuestion${keyId}/${singleOption._id}`}
                        onWork={(event) => createAction({
                            actionType: (event.isOpened ? 'expand-' : 'collapse-') + 'option',
                            actionPayload: {
                                optionId: singleOption._id,
                                aggregatorId: question._id,
                            }
                        })}
                        selected={singleOption.state === 1}
                        onChange={(e) => {
                            // the target value seems to always be on. The check is here just in case as deselecting might reasonably create an event.
                            if(e.currentTarget.value === 'on') {
                                setSelectionTo(singleOption._id)
                            }
                        }}
                    />
                })
            }
        </div>
    </QuestionCard>;
};

const OverlaySpinner = ({active}) => {
    if(!active) {
        return <></>
    }

    return <div className="overlay-spinner-radiobuttons">
        <FaSpinner className={'rotating spinner'}/>
    </div>
}

export default SingleChooseOneRadioButtonQuestion;