import {useSupportiveBackendClient} from "./useSupportiveBackendClient";
import {useCallback, useContext} from "react";
import {SupportiveBackendContext} from "../SupportiveBackendContext";
import {UpdateSessionDto} from "../models/update-session.dto";
import {useWrappedPromise} from "../../hooks/useWrappedPromise";

export const usePartialUpdateSessionApi = ()  => {
    const {client} = useSupportiveBackendClient();
    const {sessionID} = useContext(SupportiveBackendContext);

    const partiallyUpdateSession = useCallback((data: UpdateSessionDto) => {
        if(!sessionID) {
            return Promise.reject('No session ID given.');
        }

        //return client.patch(`sessions/${sessionID}`, data);
    }, [sessionID, client])

    const {wrappedPromise, data, pending, error} = useWrappedPromise(partiallyUpdateSession);

    return {
        partiallyUpdateSession: wrappedPromise,
        data,
        loading: pending,
        error,
    };
}