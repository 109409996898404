import React, {useContext} from 'react';
import Card from "react-bootstrap/Card";

import LanguageContext from "../../../contexts/LanguageContext";
import getText from "../../../util/texts/getText";

function NoAnswer() {
    const [language, ] = useContext(LanguageContext);
    return (
        <Card className={'JunBots-Answer'}>
            <Card.Body>
                <Card.Title>{getText("noAnswerFoundTitle", language)}</Card.Title>
                {getText("noAnswerFoundBody", language)}
            </Card.Body>
        </Card>
    )
}

export default NoAnswer