import {useCallback} from "react";
import {useJuriCoreClient} from "./useJuriCoreClient";

export const useSetStatusAPI = () => {
    const {client} = useJuriCoreClient();
    const setStatus = useCallback((nodeId: string, status: number) => client.patch(`/session/update/${nodeId}`, {status}), [client]);

    return {
        setStatus
    };
}