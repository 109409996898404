import Card from "react-bootstrap/Card";
import React, {useContext} from "react";
import ExpandableText from "../../misc/ExpandableText/ExpandableText";
import QuestionCard from "../shared/QuestionCard/QuestionCard";
import './MultipleChoiceCheckboxQuestion.css';
import LanguageContext from "../../../contexts/LanguageContext";
import {FaSpinner} from "react-icons/fa";
import {ChooseOneQuestion} from "../../../models/questions/ChooseOneQuestion";
import {useMultipleChoiceSelectionFunction} from "../shared/useMultipleChoiceSelectionFunction";
import AdvancedRadioButton from "../../misc/AdvancedRadioButton/AdvancedRadioButton";

interface IProps {
    question: ChooseOneQuestion,
    setStatus: (nodeID: string, newStatus: number, performNodeUpdate: boolean) => Promise<any>;
    activeAccordionKey: string;
    setActiveAccordionKey: (newKey: string) => any;
    keyId: string;
}

const MultipleChoiceCheckboxQuestion = ({
                                            question,
                                            setStatus,
                                            activeAccordionKey,
                                            setActiveAccordionKey,
                                            keyId
                                        }: IProps) => {
    const [language] = useContext(LanguageContext);

    const mainQuestion = <Card.Title style={{margin: 0}}>
        {question.mainQuestion[language] ?? question.mainQuestion.default}
    </Card.Title>

    const {answer} = useMultipleChoiceSelectionFunction(setStatus);

    const handleOnChange = async (questionId, currentState) => {
        if (currentState === 1) {
            await answer(questionId, -1)
        } else {
            await answer(questionId, 1)
        }
    }

    return <QuestionCard>
        <ExpandableText
            context={{type: 'question', id: question._id}}
            title={mainQuestion}
            expandableContent={question.moreDetails[language] ?? question.moreDetails.default}
            eventKey={question._key}
            activeAccordionKey={activeAccordionKey}
            setActiveAccordionKey={setActiveAccordionKey}
            style={{gridAutoColumns: 'auto'}}
        />

        <div className='RadioButton-Grouping'>
            <OverlaySpinner active={question.isLoading}/>
            {
                question.options.map((singleOption) => {
                    return <AdvancedRadioButton
                        type={'checkbox'}
                        name={question._id}
                        label={singleOption.selectionText[language] ?? singleOption.selectionText.default}
                        expandableText={singleOption.moreDetails ? singleOption.moreDetails[language] ?? singleOption.moreDetails.default : ''}
                        key={`SingleChooseOneWithNoneQuestion${keyId}/${singleOption._id}`}
                        onWork={(event) => {/*do nothing*/}}
                        selected={singleOption.state === 1}
                        onChange={(e) => {
                            // the target value seems to always be on. The check is here just in case as deselecting might reasonably create an event.
                            handleOnChange(singleOption._id, singleOption.state)
                        }}
                    />
                })
            }
        </div>
    </QuestionCard>;
};


const OverlaySpinner = ({active}) => {
    if (!active) {
        return <></>
    }

    return <div className="overlay-spinner-dropdown">
        <FaSpinner className={'rotating'}/>
    </div>
}

export default MultipleChoiceCheckboxQuestion;