import "./LoginRequired.scss"
import React, {HTMLAttributes} from 'react';
import {Button} from "react-bootstrap";

interface LoginPageProps extends HTMLAttributes<HTMLDivElement> {
    onLogin: () => void
}

function LoginRequired({onLogin, ...props}: LoginPageProps) {
    return (
        <div {...props} className={'LoginRequired ' + (props.className ?? '')}>
            <div className={'Main-content'}>
                <h1>Login Required</h1>
                <p>
                    Please use the credentials we sent to you via e-mail.
                </p>
                <Button onClick={() => onLogin()} variant={'danger'}>
                    Login
                </Button>
            </div>
        </div>
    )
}

export default LoginRequired;